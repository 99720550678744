<template>
  <section>
    <core-grey-error-jumbotron />
  </section>
</template>

<script>
  export default {
    components: {
      CoreGreyErrorJumbotron: () => import('./components/GreyErrorJumbotron'),
    },
  }
</script>
